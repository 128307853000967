import React, { useEffect } from 'react';
import Box from 'components/box';
import Layout from 'components/layout';
import styled from 'styled-components';

import { theme } from 'constants/theme';

const StyledHubSpotForm = styled.div`
  #hubspotForm {
    padding-top: 50px
  }

  h2 {
    margin-bottom: 50px;
    font-size: 4rem;
    font-weight: bold;
  }

  h3 {
    margin-top: 50px;
    font-size: 2.4rem;
    font-weight: bold;
  }

  a {
    color: ${theme};
    text-decoration: underline;
    font-weight: bold;
  }



  form {
    max-width: 700px;
    margin: 0 auto;
    padding: 20px;
    border: 2px solid black;
    border-radius: 22px;
  }

  fieldset {
    margin: 0 auto !important
    max-width: 600px !important;
    text-align: left;
  }

  .hs-form-field {
    margin: 20px 0 0 0 ; 
  }

  .hs_submit {
    margin: 20px auto !important
    max-width: 600px !important;
  }

  select {
    margin: 8px 0 8px 0;
    padding: 10px 23px 10px 13px;
    border-radius: 22px;
    border: 2px solid black;
    width: 100% !important;

  }

  input {
    margin: 8px 0 8px 0;
    padding: 10px 13px;
    border: none;
    border-radius: 22px;
    width: 100% !important;
    font-family: 'Montserrat', sans-serif;

    &:focus {
      outline: none;
      border: 2px solid black;
    }
  }

  label {
    font-size: 1.2rem;
    font-weight: 700;
  }

  .hs-error-msg {
    font-style: italic;
    color: ${theme};
  }

  input[type="submit"] {
    background: ${theme};
    color: white;
    font-weight: bold;

    &:hover {
      cursor: pointer;
    }
  }
`;

const Register = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = '//js.hsforms.net/forms/embed/v2.js';
    script.async = true;

    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: 'na1',
          portalId: '9415147',
          formId: '5f4b02d5-317c-4692-9c1e-63a30bdd4899',
          target: '#hubspotForm',
        });
      }
    });

    return () => {
      document.body.removeChild(script);
      script.removeEventListener('load');
    };
  }, []);

  return (
    <Layout>
      <Box>
        <StyledHubSpotForm>
          <h3>VIP Registration</h3>
          <div id="hubspotForm"></div>
        </StyledHubSpotForm>
      </Box>
    </Layout>
  );
};

export default Register;
